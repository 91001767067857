.heroSectionWrapper {
  background: #121619;
  position: relative;
  z-index: 1;
}
.heroSectionWrapper::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(181.47deg, #144d39 2.04%, #1dfe8e 191.65%);
  opacity: 0.2;
  filter: blur(251px);
}
.heroSection {
  width: 100%;
  max-width: 1536px;
  margin: 0 auto;
  position: relative;
  padding-top: 150px;
  padding-bottom: 50px;
  /* background-image: url(../../../images/heroImg.png);
  background-repeat: no-repeat;
  background-size: 45%;
  background-position: right; */
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  align-items: center;
  max-width: 1116px;
  width: 95%;
  margin: 0 auto;
}
.textContainer {
  max-width: 546px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
}
.title {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 135%;

  text-transform: uppercase;

  color: #ddffef;

  mix-blend-mode: normal;
}
.highlight {
  font-style: italic;
  font-weight: 900;

  background: linear-gradient(
    110.27deg,
    rgba(20, 77, 57, 0.2) -82.92%,
    rgb(29, 254, 142) 112.49%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  mix-blend-mode: normal;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.text {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 188%;
  color: rgba(221, 255, 239, 0.5);
  width: 100%;
  max-width: 452px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
}
.buttonContainer::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #143d2d;
  border-radius: 50%;
}
.buttonContainer::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: linear-gradient(83.79deg, #144d39 -61.08%, #1dfe8e 116.04%);
  border-radius: 50%;
}

.button {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 162%;
  text-transform: uppercase;
  color: rgba(221, 255, 239, 0.8);

  text-shadow: 0px 6px 8px rgba(24, 51, 46, 0.5);
  background: rgb(214, 237, 255, 0.2);
  border-radius: 16px;
  padding: 14px 15px;

  border: none;
  outline: none;
  min-width: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.activeButton {
  color: #18332e;

  text-shadow: 0px 6px 10px rgba(24, 51, 46, 0.4);
  background: linear-gradient(110.27deg, #144d39 -82.92%, #1dfe8e 112.49%);
}
.platforms {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
}
.iconContainer {
  display: block;

  padding: 0 15px;
}
.iconContainer:first-child {
  padding-left: 0;
}
.iconContainer:last-child {
  padding-right: 0;
}

.iconContainer:nth-child(2) {
  border-left: 1px solid rgba(221, 255, 239, 0.5);
  border-right: 1px solid rgba(221, 255, 239, 0.5);
}
.icon {
  width: 110px;
}
.imageContainer {
  width: 100%;
  max-width: 661px;
  height: 100%;
}
.image {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 100%;
  max-width: 661px;

  height: 100%;

  object-position: center;
}

@media only screen and (max-width: 1399px) {
  .image {
    max-width: 600px;
  }
}
@media only screen and (max-width: 1199px) {
  .title {
    font-size: 45px;
  }
  .image {
    max-width: 550px;
  }
  .button {
    font-size: 14px;
    padding: 14px 12px;
    min-width: 180px;
  }
}
@media only screen and (max-width: 1080px) {
  .title {
    font-size: 40px;
  }
  .text {
    font-size: 14px;
  }
  .image {
    max-width: 525px;
  }
}
@media only screen and (max-width: 991px) {
  .heroSection {
    padding-top: 0;
  }
  .wrapper {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
  .textContainer {
    padding: 0 25px;
    align-items: center;
  }
  .title {
    text-align: center;
  }
  .text {
    text-align: center;
  }
  .imageContainer {
    max-width: none;
  }
  .image {
    position: static;

    max-width: none;
  }
}
@media only screen and (max-width: 520px) {
  .title {
    font-size: 36px;
  }
  .button {
    min-width: 150px;
  }
  .icon {
    width: 100px;
  }
}
@media only screen and (max-width: 400px) {
  .title {
    font-size: 30px;
  }
  .button {
    min-width: 140px;
    padding: 10px 15px;
  }
  .platforms {
    grid-template-columns: auto auto;
  }
  .iconContainer {
    border: none;
  }
  .iconContainer:nth-child(2) {
    border-right: none;
    padding-right: 0;
  }
  .iconContainer:last-child {
    padding: 0;
    padding-top: 15px;
    grid-column: 1/-1;
    display: flex;
    justify-content: center;
  }
}
