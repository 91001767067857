.wrapper {
  display: flex;
  justify-content: center;
  color: #fff;
  background: rgba(214, 237, 255, 0.16);
  border-radius: 6px;
  padding: 4px;
  width: 100%;
}
.count {
  display: flex;

  align-items: center;
  padding: 0 10px;

  font-family: var(--secondaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;

  letter-spacing: 0.01em;

  color: #ffffff;
}
