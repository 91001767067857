.mainWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: auto;
}
.connectWalletContainer {
  width: 100%;

  border-radius: 16px;
  display: flex;
  align-items: center;

  min-height: 106px;
  position: relative;
}
.banner {
  width: 100%;
  position: absolute;
  z-index: -1;
}
.banner img {
  width: 100%;
  height: 100%;
}
.spaceBetween {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 20px 0;
  padding-top: 30px;
  padding-left: 20%;
  padding-right: 8%;
}
.title {
  font-family: var(--secondaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  text-transform: capitalize;
  color: #f1fff8;
}
.button {
  padding: 12px 20px;
  background: #1ced86;
  border-radius: 16px;
  border: none;
  outline: none;
  cursor: pointer;
}

.buttonText {
  font-family: var(--secondaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 162%;
  text-transform: capitalize;
  color: #18332e;
}
.informationContainer {
  background: rgba(214, 237, 255, 0.1);
  border-radius: 22px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.wrapper {
  background: #3b4349;
  border-radius: 16px;
  padding: 10px;
  display: flex;
  align-items: center;
}
.features {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
}
.box {
  background: #2a3035;
  border-radius: 10px;
  padding: 8px;
}
.feature {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  align-items: center;
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #454e55;
  border-radius: 6px;
  width: 30px;
  height: 30px;
}
.icon {
  width: 18px;
}
.text {
  font-family: var(--secondaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 120%;
  text-align: center;
  color: #d6edff;
  opacity: 0.8;
}
.topValue {
  opacity: 0.5;
}
.whiteListContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}
.iconAndName {
  display: flex;
  align-items: center;
  gap: 10px;
}
.whiteList {
  display: flex;
  align-items: center;
}
.isWhiteListed {
  margin-left: auto;
  display: flex;
  padding: 8px 18px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.isWhiteListed .text {
  font-size: 12px;
  font-weight: 500px;
}
.checkMarkContainer {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.checkMark {
  font-size: 16px;
}
.notWhitelisted {
  background: rgba(251, 83, 67, 0.16);
  border-radius: 6px;
}
.notWhitelisted .text {
  color: rgb(251, 83, 67, 0.8);
}
.notWhitelisted .checkMarkContainer {
  background: rgb(251, 83, 67, 0.4);
}

.notWhitelisted .checkMark {
  color: #fb5343;
  font-size: 13px;
}
.whitelisted {
  background: rgba(28, 237, 134, 0.16);
  border-radius: 6px;
}
.whitelisted .checkMarkContainer {
  background: rgb(28, 237, 134, 0.4);
}
.whitelisted .checkMark {
  color: #1ced86;
}

/* connectedWalletContainer */

.connectedWalletContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addressAndName {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.iconAndText {
  display: flex;
  align-items: center;
}
.addressContainer {
  background: rgba(214, 237, 255, 0.16);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
}
.address {
  font-family: var(--secondaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: rgba(214, 237, 255, 0.6);
  opacity: 0.8;
}
.copyIcon {
  width: 18px;
  cursor: pointer;
}
/* my Allocation */
.myAllocationContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.allocation {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.progressValueContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
}
.progressText {
  font-family: var(--secondaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: rgb(214, 237, 255, 0.5);
}
.joinWhiteList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.reserveAndPending {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  position: relative;
  z-index: 1;
}
.reserveAndPending::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 15px;
  height: 16px;

  z-index: -1;
}

.reserveAndPendingButton {
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 12px 15px;
  font-family: var(--secondaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
}
.reserveSpot {
  background: rgba(24, 51, 46, 0.8);
  box-shadow: 0px 8px 12px rgba(25, 108, 72, 0.4);
  color: #61dea8;
}

.noReserveSpot {
  background: rgba(24, 51, 46, 0.8);
  box-shadow: 0px 8px 12px rgba(25, 108, 72, 0.4);
  color: #61dea8;
}
.pending {
  background: #2a3035;
  border-radius: 10px;
  color: rgb(214, 237, 255, 0.8);
}
.skelenton .banner {
  background: var(--skelentonColor);
  color: transparent;
  height: 100px;
  border-radius: 12px;
}
.skelenton .title,
.button {
  background: var(--skelentonColor);
  color: transparent;
}
.skelenton .text,
.address,
.iconContainer,
.addressContainer,
.progressText,
.reserveAndPendingButton,
.pending {
  background: var(--skelentonColor);
  color: transparent;
}
@media only screen and (max-width: 1050px) {
  .spaceBetween {
    padding-left: 19%;
    padding-right: 6%;
  }
}
@media only screen and (max-width: 520px) {
  .mainWrapper {
    gap: 5px;
    margin-top: -25px;
  }
  .connectWalletContainer {
    padding: 30px 0;
  }
  .banner {
    object-fit: cover;
    object-position: 1%;
    height: 100%;
    width: 100%;
    margin-left: -15px;
  }
  .title {
    text-align: right;
  }
  .spaceBetween {
    flex-direction: column;
    align-items: flex-end;
  }
  .informationContainer {
    padding: 10px 7px;
  }
  .features {
    grid-template-columns: repeat(2, 1fr);
  }
  .whiteListContainer {
    grid-template-columns: 1fr;
  }
  .addressAndName {
    display: grid;
    grid-template-columns: 1fr;
  }
  .myAllocationContainer {
    grid-template-columns: 1fr;
  }
}
