.modalWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  max-width: 736px;
  width: 95%;
  margin: 0 auto;
}
.modal {
  background: #242b33;
  border-radius: 26px;

  padding: 22px;

  display: flex;
  flex-direction: column;
  gap: 12px;
}

.overlay {
  background: rgba(11, 14, 17, 0.5);
  backdrop-filter: blur(20px);
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  z-index: 9;
}
.title {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 167%;

  text-align: center;
  text-transform: uppercase;

  color: rgba(230, 243, 255, 0.8);
}
.tagline {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 200%;

  text-align: center;
  text-transform: capitalize;
  padding-bottom: 15px;

  color: rgba(229, 243, 255, 0.7);

  opacity: 0.5;
}
.details {
  display: flex;
  flex-direction: column;
  gap: 6px;
  background: rgba(229, 243, 255, 0.1);
  border-radius: 20px;
  padding: 20px;
}
.iconContainer {
  background: rgba(229, 243, 255, 0.1);
  border-radius: 14px;
  width: 42px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.icon {
  font-size: 18px;
}
.keyAndValue {
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 5px;
}
.keyWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}
.key {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 115%;

  letter-spacing: 0.01em;

  color: rgba(229, 243, 255, 0.4);
}
.valueWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}
.value {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;

  text-align: right;
  letter-spacing: -0.02em;
  font-feature-settings: "tnum" on, "lnum" on, "ss03" on;

  color: rgba(229, 243, 255, 0.6);
  white-space: nowrap;
}
.backButton {
  width: 50px;
  height: 32px;
  background: #404656;
  border-radius: 69px;
  color: #d0dce7;
  border: 4px solid #242b33;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-bottom: 20px;
}
.buttonContainer {
  display: grid;
  grid-template-columns: 1fr 0.7fr;
  align-items: center;
  gap: 12px;
}
.button {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  text-transform: capitalize;
  color: #0f381d;
  width: 100%;
  padding: 12px 10px;
  text-align: center;

  border-radius: 20px;
  cursor: pointer;
  border: none;
  outline: none;
  font-weight: 600;

  background: rgba(229, 243, 255, 0.1);

  color: #ffffff;
}
.activeButton {
  background: #1ced86;
  color: #000000;
}
@media only screen and (max-width: 767px) {
  .modal {
    padding: 16px 12px;
  }
  .details {
    padding: 12px;
  }
}
@media only screen and (max-width: 575px) {
  .details {
    gap: 20px;
  }
  .keyAndValue {
    flex-direction: column;
  }
  .keyWrapper {
    justify-content: center;
  }
  .valueWrapper {
    justify-content: center;
  }
}
@media only screen and (max-width: 400px) {
  .iconContainer {
    width: 32px;
    height: 20px;
  }
  .icon {
    font-size: 14px;
  }
  .value {
    font-size: 13px;
  }
}
