.aboutProject {
  width: 100%;
  background: rgba(214, 237, 255, 0.1);
  border-radius: 22px;
}
.topImage {
  width: 100%;
  border-radius: 22px 22px 0px 0px;
  height: 136px;
}
.topImage img {
  width: 100%;
  height: 100%;
}
.headerWrapper {
  padding: 0 20px;
  transform: translateY(-50%);
}
.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
  background: #3b4349;
  border-radius: 16px;
  padding: 8px;
}
.projectNameImage {
  display: flex;
  align-items: center;
  gap: 15px;
}
.image {
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 12px;
}
.nameAndTagline {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.name {
  font-family: var(--secondaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  text-transform: capitalize;
  color: rgba(214, 237, 255, 0.8);
}
.tagline {
  font-family: var(--secondaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: rgba(214, 237, 255, 0.6);
}

.featuresAndStatus {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  background: rgba(37, 44, 48, 0.8);
  border-radius: 10px;
  padding: 8px;
}
.features {
  display: flex;
  align-items: center;
  gap: 15px;
}
.feature {
  font-family: var(--secondaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #d6edff;
  opacity: 0.7;
  padding: 8px 15px;
  background: rgba(214, 237, 255, 0.16);
  border-radius: 10px;
}
.status {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: #1ced86;
  border-radius: 10px;
  padding: 8px 15px;
  grid-column: 1/-1;
}
.circle {
  width: 6px;
  height: 6px;

  border: 2px solid #18332e;
  border-radius: 50%;
}
.statusText {
  font-family: var(--secondaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;

  color: #18332e;
}
.bottomPart {
  padding: 0 25px;
  padding-bottom: 25px;
  margin-top: -25px;
}
.titleAndSocialMedia {
  display: flex;
  justify-content: space-between;

  align-items: center;
  padding-bottom: 15px;
}
.title {
  font-family: var(--secondaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: rgba(214, 237, 255, 0.8);
}
.socialMediaContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;

  border: 1px solid rgba(214, 237, 255, 0.12);
  border-radius: 10px;
}
.icon {
  width: 20px;
}
.text {
  font-family: var(--secondaryFont);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 186%;

  text-align: justify;
  letter-spacing: -0.01em;
  color: rgba(214, 237, 255, 0.4);
}
.textScroll {
  overflow-y: auto;
  padding-right: 8px;
  max-height: 150px;
}
.showMore {
  color: #d6edff;
  cursor: pointer;
}

.textScroll {
  scrollbar-width: thin;
}

.textScroll::-webkit-scrollbar {
  width: 5px;
}

.textScroll::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.2);
}

.textScroll::-webkit-scrollbar-thumb {
  border-radius: 100vh;
  border: none;
}
.skelenton .projectNameImage {
  background-color: var(--skelentonColor);
  border-radius: 10px;
}
.skelenton .topImage {
  border-radius: 22px 22px 0px 0px;
}
.topImage img {
  visibility: hidden;
}
.skelenton .projectNameImage img {
  visibility: hidden;
}
.skelenton .status {
  background-color: var(--skelentonColor);
  color: transparent;
}
.skelenton .feature {
  background-color: var(--skelentonColor);
  color: transparent;
}
.skelenton .text,
.title {
  background-color: var(--skelentonColor);
  color: transparent;
}
.skelenton .iconContainer {
  background-color: var(--skelentonColor);
  color: transparent;
  border: none;
}
@media only screen and (max-width: 520px) {
  .headerWrapper {
    padding: 0 10px;
  }
  .header {
    padding: 5px;
  }
  .projectNameImage {
    gap: 10px;
  }
  .image {
    width: 60px;
    height: 60px;
  }
  .featuresAndStatus {
    gap: 5px 2.5px;
    padding: 5px;
  }
  .features {
    gap: 3px;
  }
  .status {
    padding: 5px 10px;
  }
  .feature {
    padding: 5px 10px;
  }
  .bottomPart {
    padding: 0 15px;
    padding-bottom: 25px;
  }
}
@media only screen and (max-width: 374px) {
  .headerWrapper {
    padding: 0 5px;
  }
  .projectNameImage {
    gap: 8px;
  }
  .image {
    width: 50px;
    height: 50px;
  }
  .name {
    font-size: 18px;
  }
  .tagline {
    font-size: 12px;
  }
}
