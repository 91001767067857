.projectInfo {
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: -100px;
}
.counterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
  background: rgba(36, 43, 51, 0.8);
  backdrop-filter: blur(50px);
  border-radius: 26px;
  width: 33.33%;
  padding: 40px 15px;
}
.text {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  text-transform: capitalize;
  color: rgba(229, 243, 255, 0.6);

  border-top: 1px dashed rgba(229, 243, 255, 0.22);
  width: 100%;
  padding-top: 30px;
}
@media only screen and (max-width: 991px) {
  .projectInfo {
    justify-content: center;
    flex-wrap: wrap;
  }
  .counterContainer {
    width: calc(50% - 20px);
  }
}
@media only screen and (max-width: 767px) {
  .projectInfo {
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
    margin-top: -100px;
  }
  .counterContainer {
    width: 100%;
  }
}
