.navbar {
  position: fixed;
  z-index: 9;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 101px;
}
.navBg {
  background: #121619;
}
.navs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navItemsAndButtons {
  display: flex;
  align-items: center;
  gap: 80px;
}
.logoContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}
.logo {
  width: 24px;
  height: 24px;
}
.logoText {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: #ddffef;
}
.navItems {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 35px;
}
.navItem {
  font-family: var(--fontFamily);

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 186%;
  text-transform: capitalize;
  color: #ddffef;
}
.navItemActive {
  font-family: var(--fontFamily);

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 186%;
  text-transform: capitalize;
  color: #ddffef;
}
.buttonContainer {
  display: flex;
  align-items: center;
  gap: 15px;
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: rgba(221, 255, 239, 0.2);
  border-radius: 16px;
}
.button {
  padding: 7px 20px;

  background: #ddffef;
  border-radius: 16px;
  border: none;
  outline: none;
  cursor: pointer;
}
.buttonText {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 186%;
  text-transform: capitalize;
  color: #18332e;
}

.hamburger,
.close {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
}
.close {
  position: absolute;
  top: 35px;
  right: 20px;
}
@media only screen and (max-width: 1199px) {
  .navItemsAndButtons {
    gap: 60px;
  }
  .iconContainer {
    width: 40px;
    height: 40px;
  }
  .icon {
    width: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .navbar {
    padding: 0 15px;
    height: 90px;
  }
  .navItemsAndButtons {
    position: absolute;
    top: 0px;
    right: -150%;
    width: 230px;
    background: #121f1e;

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    min-height: 100vh;
    padding: 80px 40px;
    padding-bottom: 30px;
    transition: 0.8s ease;
  }

  .sidebar {
    right: 0;
    transition: 0.4s ease;
    z-index: 10;
  }
  .navItems {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .buttonContainer {
    flex-wrap: wrap;
  }
  .hamburger,
  .close {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .button {
    padding: 7px 15px;
  }
}

@media only screen and (max-width: 380px) {
  .button {
    padding: 6px 12px;
  }

  .hamburger,
  .close {
    font-size: 24px;
  }
}
