.launchPad {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  align-items: start;
  padding: 70px 0;
  padding-top: 180px;
  grid-auto-flow: dense;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.rateContainer {
  background: rgba(214, 237, 255, 0.1);
  border-radius: 22px;
  padding: 15px;
}
.mobileAboutProject {
  display: none;
}
@media only screen and (max-width: 991px) {
  .launchPad {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    gap: 25px;
    padding-top: 130px;
  }
  .wrapper {
    width: 100%;
    gap: 25px;
  }
  .aboutProject {
    display: none;
  }
  .mobileAboutProject {
    display: block;
  }
}
@media only screen and (max-width: 991px) {
  .launchPad {
    gap: 15px;
  }
  .wrapper {
    gap: 15px;
  }
  .aboutProject {
    display: none;
  }
  .mobileAboutProject {
    display: block;
  }
}
