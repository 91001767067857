.wrapper {
  padding: 100px 0;
}
.titleAndHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
  gap: 15px;
}
.title {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  text-transform: capitalize;
  color: rgba(230, 243, 255, 0.8);
}
.searchAndFilter {
  display: flex;
  gap: 15px;
  align-items: center;
}
.searchIcon {
  color: rgba(229, 243, 255, 0.3);
  font-size: 18px;
  margin-top: 2px;
}
.inputContainer {
  display: flex;
  align-items: center;

  padding: 5px 15px;
  border: 1px solid rgba(229, 243, 255, 0.16);
  border-radius: 20px;

  width: 296px;
}
.input {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: rgba(229, 243, 255, 0.5);

  width: 100%;
  padding: 8px 10px;
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
}
.dropdown {
  display: flex;
  gap: 8px;
  align-items: center;
  position: relative;
  border: 1px solid rgba(229, 243, 255, 0.26);
  border-radius: 20px;

  padding: 7px 15px;
}
.dropDownItems {
  position: absolute;
  z-index: 5;
  min-width: 100%;
  top: 50px;
  right: 0px;

  background: #242b33;
  border: 2px solid rgba(229, 243, 255, 0.1);
  box-shadow: 0px 24px 70px 10px rgba(18, 23, 30, 0.8);
  border-radius: 20px;

  padding: 8px 5px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}
.sortValue {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: rgba(229, 243, 255, 0.5);
  width: 100%;
  padding: 4px 0;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.sort {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: rgba(229, 243, 255, 0.46);
  border-right: 1px solid rgba(229, 243, 255, 0.26);
  padding-right: 12px;
  display: block;
}
.sortBy {
  padding-left: 12px;
  display: block;
}
.dropDownItem {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: rgba(229, 243, 255, 0.5);
  width: 100%;
  padding: 10px 14px;
}
.dropDownItem:hover {
  background: #1ced86;
  border-radius: 16px;
  color: #0f381d;
}
.dropDownItems .dropDownItem {
  padding-right: 20px;

  cursor: pointer;
}
.arrow {
  color: rgba(229, 243, 255, 0.36);
  font-size: 24px;
  cursor: pointer;
}
.projects {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

@media only screen and (max-width: 991px) {
  .projects {
    grid-template-columns: 1fr;
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
  }
  .titleAndHeader {
    max-width: 550px;
    margin: 0 auto;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 767px) {
  .searchAndFilter {
    flex-direction: column;
    align-items: flex-start;
  }
  .dropdown {
    width: 100%;
  }
}
