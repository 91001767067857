.createToken {
  background: #242b33;
  border-radius: 26px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 22px;
  position: relative;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2e3443;
  opacity: 0.7;
  filter: blur(5px);
  border-radius: 26px;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}
.title {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-transform: capitalize;
  color: rgba(230, 243, 255, 0.8);
}
.text {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 200%;

  text-transform: capitalize;

  color: rgba(229, 243, 255, 0.7);
  opacity: 0.5;
}
.dropdown {
  display: flex;
  gap: 8px;
  align-items: center;
  position: relative;
  border: 1px solid rgba(229, 243, 255, 0.26);
  border-radius: 20px;

  padding: 7px 15px;
}
.dropDownItems {
  position: absolute;
  z-index: 5;
  min-width: 100%;
  top: 50px;
  right: 0px;

  background: #242b33;
  border: 2px solid rgba(229, 243, 255, 0.1);
  box-shadow: 0px 24px 70px 10px rgba(18, 23, 30, 0.8);
  border-radius: 20px;

  padding: 8px 5px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}
.sortValue {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: rgba(229, 243, 255, 0.5);
  width: 100%;
  padding: 4px 0;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.sort {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: rgba(229, 243, 255, 0.46);
  border-right: 1px solid rgba(229, 243, 255, 0.26);
  padding-right: 12px;
  display: block;
}
.sortBy {
  padding-left: 12px;
  display: block;
}
.dropDownItem {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: rgba(229, 243, 255, 0.5);
  width: 100%;
  padding: 10px 14px;
}
.dropDownItem:hover {
  background: #1ced86;
  border-radius: 16px;
  color: #0f381d;
}
.dropDownItems .dropDownItem {
  padding-right: 20px;

  cursor: pointer;
}
.arrow {
  color: rgba(229, 243, 255, 0.36);
  font-size: 24px;
  cursor: pointer;
}
/* form */
.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.searchIcon {
  color: rgba(229, 243, 255, 0.3);
  font-size: 18px;
  margin-top: 2px;
}
.inputWrapper {
  width: 100%;
}
.label {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: rgba(229, 243, 255, 0.4);
  padding: 0px 8px;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.fee {
  padding: 15px 8px;
  padding-top: 10px;
}
.inputContainer {
  display: flex;
  align-items: center;

  padding: 5px 15px;
  background: rgba(229, 243, 255, 0.1);
  border-radius: 20px;

  width: 100%;
}
.input {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: rgba(229, 243, 255, 0.5);

  width: 100%;
  padding: 8px 10px;
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
}
.button {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  text-transform: capitalize;
  color: #0f381d;
  width: 100%;
  padding: 12px 10px;
  text-align: center;
  background: #1ced86;
  border-radius: 14px;
  cursor: pointer;
  border: none;
  outline: none;
  font-weight: 600;
}
